import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import {GiExitDoor} from 'react-icons/gi';
import {RiAccountPinCircleFill} from 'react-icons/ri';
import {SiMinutemailer} from 'react-icons/si';
import dayjs from 'dayjs';
import './CProfile.css';
import {analyticsPageView} from '../core/AnalyticsManager';
import CEditCountry from './CEditCountry'
import CEditAddress from './CEditAddress'
import CBreadcrumb from '../common/CBreadcrumb';
import CLoadInProgress from '../common/CLoadInProgress';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';

class CProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countries: null,
            errorMessage: null,
            updateInProgress: false,
        }
    }

    componentDidMount() {
        this._refocus();
        const {t} = this.props;
        document.title = t('profile.title');
        analyticsPageView('profil');
    }

    _refocus() {
        if (this.props.me && !this.props.me.profile) {
            document.location.href = '/';
        }
    }

    render() {
        return this.props.me && this.props.me.profile ?
            this.renderProfile() : null;
    }

    renderProfile() {
        if (this.state.updateInProgress) {
            return <CLoadInProgress/>;
        }
        const {t} = this.props;
        const propsMe = this.props.me;
        const displayName = _.get(propsMe, 'profile.displayName', null);
        const email = _.get(propsMe, 'profile.email', null);
        const country = _.get(propsMe, 'profile.country', null);
        const createTs = _.get(propsMe, 'profile.createTs', null) ? dayjs(new Date(propsMe.profile.createTs * 1000)).format("DD/MM/YYYY") : null;
        const breadcrumb = (<CBreadcrumb activeLabel={t('breadcrumb.profile')}/>);
        return (
            <div className="CProfile">
                {breadcrumb}
                <div className="alignCenter">
                    <div className="card">
                        <div className="card-body">

                            {displayName ? (
                                <div className="form-group">
                                    <label><RiAccountPinCircleFill/> {t('profile.user')}</label>
                                    <div style={{float: 'right'}}><a
                                        href="/api/v0/logout"><GiExitDoor/> {t('profile.logout')}</a></div>
                                    <input name="displayName"
                                           type="text"
                                           className="form-control disabled profileValue"
                                           value={displayName}
                                           disabled
                                    />
                                    {createTs ? (
                                        <Form.Text className="text-muted">
                                            {t('profile.registerDate')} {createTs} - {t('profile.cnil')} <a
                                            href="/cgv#c9">{t('profile.cnilLink')}</a>
                                        </Form.Text>
                                    ) : (null)}
                                </div>
                            ) : null}
                            {email ? (
                                <div className="form-group">
                                    <label><SiMinutemailer/> {t('profile.email')}</label>
                                    <input name="userEmail"
                                           type="text"
                                           className="form-control disabled profileValue"
                                           value={email}
                                           disabled
                                    />
                                </div>
                            ) : null}

                            <CEditAddress meProfile={this.props.me.profile} country={country}
                                          onUpdated={this.props.updated.bind(this, t('profile.addressUpdated'))}/>

                            <CEditCountry meProfile={this.props.me.profile}
                                          updated={this.props.updated.bind(this, t('profile.countryUpdated'))}/>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation('common')(CProfile);