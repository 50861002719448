import React, {Component} from 'react';
import {analyticsPageView} from './AnalyticsManager';
import {withTranslation} from 'react-i18next';

class CNotFound extends Component {
    componentDidMount() {
        const {t} = this.props;
        document.title = t('notFound.title');
        analyticsPageView('notFound');
    }

    render() {
        const {t} = this.props;
        return (
            <div className="cnotfound">
                <h1>{t('notFound.pTitle')}</h1>
                <p>{t('notFound.pBody')}</p>
            </div>
        );
    }
}

export default withTranslation('common')(CNotFound);