import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable'
import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";

import CApp from './CApp';
import * as serviceWorker from './serviceWorker';
// credit i18n https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-react-app-with-react-i18next
import {I18nextProvider} from "react-i18next";
import i18n from './i18n.js';

const root = createRoot(document.getElementById('root'));
root.render(<I18nextProvider i18n={i18n}>
    <BrowserRouter>
        <CApp/>
    </BrowserRouter>
</I18nextProvider>);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
