import React, {Component} from 'react'
import './CHead.css';
import {withTranslation} from 'react-i18next';

class CHead extends Component {

    render() {
        const {t} = this.props;
        return (
            <a href="/">
                <div id="conteneur">
                    <img src="/images/top_bandeau_bougies_femme3.jpg" alt={t('ch.head')}/>
                    <div id="masque">
                        <div className="chead">
                            <div className="chleft">{t('head.title')}</div>
                            <div className="chright1 subtitle">{t('head.right1')}</div>
                            <div className="chright2 subtitle">{t('head.right2')}</div>
                        </div>
                    </div>
                </div>
            </a>
        );
    }
}

export default withTranslation('common')(CHead);