import React, {Component} from 'react';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {withTranslation} from 'react-i18next';
import {
    FacebookIcon,
    FacebookShareButton,
    PinterestIcon,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon
} from "react-share";

import './CListingShareButtons.css';
import {analyticsEvent} from '../core/AnalyticsManager';
import {CConstants} from '../common/CConstants';

class CListingShareButtons extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    sharedEvent(target) {
        const evt = "shared on " + target;
        analyticsEvent(CConstants.GG_CATEGORY.LISTING, evt, evt);
    }

    render() {
        const {
            t, listingUrl,
            shareTitle, shareTags, shareTag, shareIconSize,
            shareVia, shareMedia, sharedOn
        } = this.props;
        // const shareTags = this.props.tags; // hashtags={shareTags}
        // https://github.com/nygardk/react-share
        return (
            <div className="clistingShareButtons">
                {t('listing.details.share')} :<br/>
                <TwitterShareButton url={listingUrl}
                                    data-tooltip-content={sharedOn + " X (ex. Twitter)"}
                                    id="ttShareTwitter"
                                    title={shareTitle} via={shareVia} hashtags={shareTags}
                                    onShareWindowClose={() => this.sharedEvent("Twitter")}
                                    className="chSharedButton">
                    <XIcon size={shareIconSize} round/>
                </TwitterShareButton>
                <FacebookShareButton url={listingUrl}
                                     data-tooltip-content={sharedOn + " Facebook"}
                                     id="ttShareFacebook"
                                     quote={shareTitle} hashtag={shareTag}
                                     onShareWindowClose={() => this.sharedEvent("Facebook")}
                                     className="chSharedButton">
                    <FacebookIcon size={shareIconSize} round/>
                </FacebookShareButton>
                {shareMedia ? (
                    <PinterestShareButton url={listingUrl}
                                          data-tooltip-content={sharedOn + " Pinterest"}
                                          id="ttSharePinterest"
                                          media={shareMedia}
                                          description={shareTitle}
                                          onShareWindowClose={() => this.sharedEvent("Pinterest")}
                                          className="chSharedButton">
                        <PinterestIcon size={shareIconSize} round/>
                    </PinterestShareButton>
                ) : null}
                <WhatsappShareButton url={listingUrl}
                                     data-tooltip-content={sharedOn + " Whatsapp"}
                                     id="ttShareWhatsapp"
                                     title={shareTitle}
                                     onShareWindowClose={() => this.sharedEvent("Whatsapp")}
                                     className="chSharedButton">
                    <WhatsappIcon size={shareIconSize} round/>
                </WhatsappShareButton>
                <ReactTooltip anchorId="ttShareTwitter"/>
                <ReactTooltip anchorId="ttShareFacebook"/>
                <ReactTooltip anchorId="ttSharePinterest"/>
                <ReactTooltip anchorId="ttShareWhatsapp"/>
            </div>
        );
    }

}

export default withTranslation('common')(CListingShareButtons);