// fonts
import './fonts/Lobster-Regular.ttf'
import './fonts/magic-school.one.ttf'

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

// ReactTooltip
import 'react-tooltip/dist/react-tooltip.css'

import React, {Component, lazy, Suspense} from 'react';
import {Route, Routes, useParams} from "react-router-dom";

import './CApp.css';

import {Alert, Container} from 'react-bootstrap';
import './commons.js';
import CUtils from './services/CUtils';
import CHead from './core/CHead';
import CMenu from './core/CMenu';
import CCookiesModal from './core/CCookiesModal';
import CLeftMenu from './core/CLeftMenu';
import CFooter from './core/CFooter';
import CNotFound from './core/CNotFound';
import CLoadInProgress from './common/CLoadInProgress';

import CHome from './home/CHome';
import CList from './listings/CList';
import CListing from './listing/CListing';
import CListingId from './listing/CListingId';
import CCart from './cart/CCart';
import CProfile from './auth/CProfile';
import CLogin from './auth/CLogin';
import CPassword from './auth/CPassword';


import ApiV0 from './services/ApiV0';
import {withTranslation} from 'react-i18next';

import './loader.js'; // jquery

const CSearchResult = lazy(() => import('./home/CSearchResult'));

const CAdmin = lazy(() => import('./admin/CAdmin'));
const CAdminMenu = lazy(() => import('./core/CAdminMenu'));
const CAdminEditChListing = lazy(() => import('./admin/listings/CAdminEditChListing'));
const CAdminEditEtsyListing = lazy(() => import('./admin/listings/CAdminEditEtsyListing'));

const CRegister = lazy(() => import('./auth/CRegister'));
const CRemindPassword = lazy(() => import('./auth/CRemindPassword'));
const CCommand = lazy(() => import('./command/CCommand'));

const CWho = lazy(() => import('./officials/CWho'));
const CCGV = lazy(() => import('./officials/CCGV'));
const CAbout = lazy(() => import('./officials/CAbout'));
const CCredits = lazy(() => import('./officials/CCredits'));
const CContact = lazy(() => import('./officials/CContact'));
const CData = lazy(() => import('./officials/CData'));
const CLivraison = lazy(() => import('./officials/CLivraison'));

const IS_BOT_DEBUG = process.env.CH_IS_BOT_DEBUG === "true";

const currentPath = window.location.pathname;
const isBot = window.reqInfo ? IS_BOT_DEBUG || window.reqInfo.isBot === true : false;
const showConsent = (!CUtils.hasConsentCookie() && isBot === false) || "/cookies" === currentPath;

// DEBUG // console.log({currentPath, isBot, showConsent})
let initialLoading = false;


class CApp extends Component {
    state = {
        infoMessage: null,
        errorMessage: null,
        modalMessage: null,
        me: null,
        showInfo: false,
        isConnected: false,
        isAdmin: false,
        sectionLoaded: false,
        showConsent,
        q: null
    };

    constructor(props) {
        super(props);
        this.appAlert = React.createRef();
        this.appContainer = React.createRef();
    }

    componentDidMount() {
        if (this.state.errorMessage != null) {
            return;
        }
        if (initialLoading === false) {
            initialLoading = true;
            this.doMe();
        }
    }

    doMe() {
        const app = this;
        ApiV0.me()
            .then(meResult => {
                const isConnected = (meResult && meResult.profile && meResult.profile.displayName);
                const isAdmin = (isConnected && meResult.profile.isAdmin === true);
                // redirect to current unpaid command if any
                const hasUnpaid = (meResult && meResult.unpaidCommands && meResult.unpaidCommands.length > 0);
                if (hasUnpaid && currentPath.indexOf('/command') !== 0) {
                    document.location.href = "/command";
                }
                // continue to load sections
                app.setState({me: meResult, isConnected, isAdmin}, () => app.doLoadSections());
            })
            .catch(err => {
                app.setState({me: {}}, () => app.doLoadSections());
            });
    }


    doLoadSections() {
        if (this.state.sectionLoaded === true) {
            return;
        }
        // DEBUG // console.log("Capp::doLoadSections")
        const app = this;
        const {t} = this.props;
        ApiV0.getSections()
            .then(sections => {
                const activeSections = sections.filter(s => s.ch_listing_count > 0);
                // DEBUG // console.log("CApp:: >> sections", JSON.stringify(activeSections))
                app.setState({allSections: sections, sections: activeSections, sectionLoaded: true});
            })
            .catch(apiError => {
                const errorMessage = CUtils.userErrorOf("menu load error", t, apiError);
                console.error(errorMessage);
                app.setState({sections: [], sectionLoaded: true});
            });
    }

    onJustRegistered() {
        const {t} = this.props;
        this.setState({modalMessage: t('app.registered'), modalMessageBis: t('app.registeredBis')});
        this.infoAutoHide();
    }

    onJustRemind() {
        const {t} = this.props;
        this.setState({modalMessage: t('app.reminded'), modalMessageBis: t('app.remindedBis')});
        this.infoAutoHide();
    }

    onJustCompleted() {
        // nothing here
    }

    onCartUpdated(infoMessage) {
        if (infoMessage !== undefined && infoMessage !== null && infoMessage !== '') {
            this.setState({infoMessage});
            this.infoAutoHide();
        }
        this.doMe();
    }

    onProfileUpdated(infoMessage) {
        if (infoMessage) {
            this.setState({infoMessage});
            this.infoAutoHide();
        }
        this.doMe();
    }

    cartEntryQuantity(listing_id, variationKey, variationValue, customizationValue) {
        if (!this.state.me || !this.state.me.cart) {
            return 0;
        }
        var cart = this.state.me.cart;
        var entries = [];
        if (window.isSet(customizationValue)) {
            entries = cart.entries.filter(e => {
                return (e.listing_id === listing_id && e.customization_value === customizationValue);
            });
        } else if (window.isSet(variationKey) && window.isSet(variationValue)) {
            entries = cart.entries.filter(e => {
                return (e.listing_id === listing_id && e.variation_key === variationKey && e.variation_value === variationValue);
            });
        } else {
            entries = cart.entries.filter(e => {
                return (e.listing_id === listing_id);
            });
        }
        const reducerAdd = (accumulator, currentValue) => accumulator + currentValue;
        return Array.isArray(entries) ? entries.map(e => e.quantity).reduce(reducerAdd, 0) : 0;
    }

    cartFindEntry(listing_id, variationKey, variationValue, customizationValue) {
        if (!this.state.me || !this.state.me.cart) {
            return null;
        }
        var cart = this.state.me.cart;
        if (window.isSet(customizationValue)) {
            return cart.entries.find(e => {
                return (e.listing_id === listing_id && e.customization_value === customizationValue);
            });
        }
        if (window.isSet(variationKey) && window.isSet(variationValue)) {
            return cart.entries.find(e => {
                return (e.listing_id === listing_id && e.variation_key === variationKey && e.variation_value === variationValue);
            });
        }
        return cart.entries.find(e => {
            return (e.listing_id === listing_id);
        });
    }

    infoHide() {
        this.setState({showInfo: false});
    }

    infoAutoHide(timeoutMs = 50000) {
        const app = this;
        this.setState({
            showInfo: true
        }, () => {
            setTimeout(() => {
                app.infoHide();
            }, timeoutMs)
        });
    }

    render() {
        if (this.state.sectionLoaded === false) {
            return renderLoader();
        }

        // DEBUG // console.log("CApp::render")
        const {
            isAdmin, isConnected,
            sections, allSections,
            "q": searchValue
        } = this.state;
        const contentStyle = (isAdmin && '/admin' === currentPath)
        || ["", "/", "/home", "/search", "/cookies"].includes(currentPath)
        || currentPath.match(/^\/listing\/[0-9a-z\\-]+$/) ? "CContent" :
            ["/command"].includes(currentPath) ? "CContentNormal" : "CContentFull";

        const errorMessage = this.state.errorMessage ? (
            <Alert variant="warning">{this.state.errorMessage}</Alert>) : null;
        const infoMessage = (<Alert variant="info" show={this.state.showInfo}
                                    ref={this.appAlert}><span>{this.state.infoMessage}</span></Alert>);
        const messages = (<span>{errorMessage}{infoMessage}</span>);
        const modalMessage = window.isSet(this.state.modalMessage) ?
            (<div className="modalParent">
                <div className="modalChild">{this.state.modalMessage}<br/>{this.state.modalMessageBis}</div>
            </div>)
            : null;

        const cContainer = (<div id="CContainer">
                <Routes>
                    {["/", "/home", "/search", "/cookies", "/listing/:sectionId"].map((path, index) => {
                        return (<Route path={path} key={index} element={<CSideBar sections={sections}/>}/>);
                    })}
                    {isAdmin ? (<>
                        {["/admin"].map((path, index) => {
                            return (<Route path={path} key={index} element={<CAdminSideBar/>}/>);
                        })}
                    </>) : null}
                    <Route path='*' element={null}/>
                </Routes>
                <div id={contentStyle}>
                    <div id="CMainContent">
                        <Routes>
                            {/* Home */}
                            {["/", "/home", "/cookies"].map((path, index) => {
                                return (
                                    <Route path={path} key={index} element={<CHome me={this.state.me}/>}/>
                                );
                            })}

                            {/* Auth */}
                            <Route path="/login" element={<CLogin/>}/>
                            <Route path="/register" element={<Suspense fallback={renderLoader()}>
                                <CRegister onJustRegistered={this.onJustRegistered.bind(this)}/>
                            </Suspense>}/>
                            <Route path="/remind" element={<Suspense fallback={renderLoader()}>
                                <CRemindPassword onJustRemind={this.onJustRemind.bind(this)}/>
                            </Suspense>}/>
                            <Route path="/cb/:tokenId"
                                   element={<CallbackPassword onJustRegistered={this.onJustCompleted.bind(this)}/>}/>

                            {/* Body */}
                            <Route path="/listing/:sectionId" element={<ShowListingSection/>}/>
                            <Route path="/listing/:sectionId/:listingId"
                                   element={<ShowListing onCartUpdated={this.onCartUpdated.bind(this)}
                                                         cartFindEntry={this.cartFindEntry.bind(this)}
                                                         cartEntryQuantity={this.cartEntryQuantity.bind(this)}
                                                         isAdmin={isAdmin}
                                                         isConnected={isConnected}/>}/>
                            <Route path="/lid/:listingId" element={<ShowListingId/>}/>
                            <Route path="/listing" element={<CList/>}/>

                            {/* Head */}
                            <Route path="/me" element={<CProfile me={this.state.me}
                                                                 updated={this.onProfileUpdated.bind(this)}/>}/>
                            <Route path="/cart" element={<CCart me={this.state.me}
                                                                onUpdated={this.onProfileUpdated.bind(this)}
                                                                onCartUpdated={this.onCartUpdated.bind(this)}/>}/>
                            <Route path="/command/*" element={<Suspense fallback={renderLoader()}>
                                <CCommand me={this.state.me}
                                          onUpdated={this.onProfileUpdated.bind(this)}
                                          onProfileUpdated={this.onProfileUpdated.bind(this)}/>
                            </Suspense>}/>
                            <Route path="/search" element={<Suspense fallback={renderLoader()}>
                                <CSearchResult searchValue={searchValue}/>
                            </Suspense>}/>
                            {/* Footer */}
                            <Route path="/about" element={<Suspense fallback={renderLoader()}><CAbout/></Suspense>}/>
                            <Route path="/cgv" element={<Suspense fallback={renderLoader()}><CCGV/></Suspense>}/>
                            <Route path="/livraison"
                                   element={<Suspense fallback={renderLoader()}><CLivraison/></Suspense>}/>
                            <Route path="/who" element={<Suspense fallback={renderLoader()}><CWho/></Suspense>}/>
                            <Route path="/contact"
                                   element={<Suspense fallback={renderLoader()}><CContact/></Suspense>}/>
                            <Route path="/donnees" element={<Suspense fallback={renderLoader()}><CData/></Suspense>}/>
                            <Route path="/credits"
                                   element={<Suspense fallback={renderLoader()}><CCredits/></Suspense>}/>

                            {/* Admin */}
                            {isAdmin ? (<Route path="/admin/listings"
                                               element={<EditChListing sections={allSections}/>}/>) : null}
                            {isAdmin ? (<Route path="/admin/listing/:sectionId/:listingId"
                                               element={<EditEtsyListing/>}/>) : null}
                            {isAdmin ? (<Route path="/admin" element={<Suspense fallback={renderLoader()}>
                                <CAdmin me={this.state.me} updated={this.onProfileUpdated.bind(this)}/>
                            </Suspense>}/>) : null}

                            {/* default: not found */}
                            <Route path='*' element={<CNotFound/>}/>
                        </Routes>
                        <CFooter/>
                    </div>
                </div>
            </div>
        );

        return (<span className="website-font-change">
            {showConsent ? (<CCookiesModal/>) : null}
            <div id="cBackground" className="cbackground"/>
            <Container ref={this.appContainer} id="chContainer">
                <div className="app" id="chApp">
                    <div className="CHead"><CHead/></div>
                    <CMenu me={this.state.me} sections={sections}/>
                    {modalMessage ? modalMessage : (<>
                        {messages}
                        {cContainer}
                    </>)}
                </div>
            </Container>
        </span>);
    }

}

export default withTranslation('common')(CApp);

function CallbackPassword(props) {
    const params = useParams();
    return (<CPassword tokenId={params.tokenId} onJustRegistered={props.onJustRegistered}/>);
}

function ShowListingSection(props) {
    const params = useParams();
    return (<CList sectionId={params.sectionId}/>);
}

function ShowListing(props) {
    const params = useParams();
    return (<CListing onCartUpdated={props.onCartUpdated}
                      cartFindEntry={props.cartFindEntry}
                      cartEntryQuantity={props.cartEntryQuantity}
                      sectionId={params.sectionId}
                      listingId={params.listingId}
                      isAdmin={props.isAdmin}
                      isConnected={props.isConnected}
    />);
}

function ShowListingId(props) {
    const params = useParams();
    return (<CListingId listingId={params.listingId}/>);

}

function EditChListing(props) {
    return (<Suspense fallback={renderLoader()}><CAdminEditChListing sections={props.sections}/></Suspense>);
}

function EditEtsyListing(props) {
    const params = useParams();
    return (<Suspense fallback={renderLoader()}><CAdminEditEtsyListing listingId={params.listingId}
                                                                       sectionId={params.sectionId}/></Suspense>);
}

function CSideBar(props) {
    var sections = props.sections;
    return (<div id="CSidebar" className="appSideBar">
        <div id="CSidebar-content"><CLeftMenu sections={sections}/></div>
    </div>);
}

function CAdminSideBar(props) {
    return (<div id="CAdminSidebar" className="appAdminSideBar">
        <div id="CAdminSidebar-content"><Suspense fallback={renderLoader()}><CAdminMenu/></Suspense></div>
    </div>);
}

function renderLoader() {
    return (<CLoadInProgress/>);
}
