import React, {Component} from 'react';
import {Alert, Card, Col, Form, Row} from 'react-bootstrap';

import {TiDelete, TiEdit} from 'react-icons/ti';
import {FaAddressCard} from 'react-icons/fa';

import {CConstants} from '../common/CConstants';
import {analyticsEvent} from '../core/AnalyticsManager';
import CFormTip from '../common/CFormTip';
import CLoadInProgress from '../common/CLoadInProgress';
import ApiV0 from '../services/ApiV0';
import CUtils from '../services/CUtils';
import {withTranslation} from 'react-i18next';

const KEY_ESCAPE = 27;

class CEditAddress extends Component {
    constructor(props) {
        super(props);
        const {
            firstname,
            lastname,
            streetNumber,
            streetName,
            additionalAddress,
            zipCode,
            city
        } = this.getDefaultAddress();
        const editAddress = !(firstname !== ""
            && lastname !== ""
            && streetName !== ""
            && zipCode !== ""
            && city !== ""
        )
        this.state = {
            editAddress,
            firstname,
            lastname,
            streetNumber,
            streetName,
            additionalAddress,
            zipCode,
            city,
            errorMessage: null,
            showWarn: false,
            wip: false
        };
    }

    getDefaultAddress() {
        if (this.props.meProfile.address) {
            const {
                firstname,
                lastname,
                streetNumber,
                streetName,
                additionalAddress,
                zipCode,
                city
            } = this.props.meProfile.address;
            return {firstname, lastname, streetNumber, streetName, additionalAddress, zipCode, city};
        }
        return {
            firstname: "",
            lastname: "",
            streetNumber: "",
            streetName: "",
            additionalAddress: "",
            zipCode: "",
            city: ""
        };
    }

    doEditAddress() {
        this.setState({editAddress: true}, () => this.doNotifyMode());
    }

    doEndEdit(callback = null) {
        this.setState({editAddress: false, wip: false, errorMessage: null}, () => {
            if (callback) {
                callback();
            }
            this.doNotifyMode();
        });
    }

    doNotifyMode() {
        if (!this.props.onEditMode) {
            return;
        }
        this.props.onEditMode(this.state.editAddress);
    }

    doRemoveAddress() {
        const {t} = this.props;
        const displayName = this.props.meProfile.displayName;
        this.setState({errorMessage: null, showWarn: false, wip: true}, () =>
            ApiV0.removeMeAddress()
                .then(removeAddressSuccessData => {
                    console.info("removeAddressSuccess", removeAddressSuccessData);
                    const evt = `remove address`;
                    analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, displayName);
                    this.setState({editAddress: false, wip: false}, () => this.props.onUpdated());
                })
                .catch(apiError => {
                    const errorMessage = CUtils.userErrorOf("remove address", t, apiError);
                    const evt = `remove address error`;
                    analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, displayName);
                    this.setState({errorMessage, wip: false}, () => this.warnAutoHide(50000));
                })
        );
    }

    doCancelAddress() {
        console.log("doCancelAddress");
        const {
            firstname,
            lastname,
            streetNumber,
            streetName,
            additionalAddress,
            zipCode,
            city
        } = this.getDefaultAddress();
        const revertState = {
            editAddress: false,
            firstname,
            lastname,
            streetNumber,
            streetName,
            additionalAddress,
            zipCode,
            city,
            errorMessage: null,
            showWarn: false
        };
        this.setState(revertState, () => this.doNotifyMode());
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({errorMessage: null, showWarn: false, wip: true}, () =>
            this.doSaveAddress(this.state.firstname, this.state.lastname,
                this.state.streetNumber, this.state.streetName,
                this.state.additionalAddress,
                this.state.zipCode, this.state.city)
        );
    }

    doSaveAddress(firstname, lastname, streetNumber, streetName, additionalAddress, zipCode, city) {
        const {t} = this.props;
        const displayName = this.props.meProfile.displayName;
        const options = {firstname, lastname, streetNumber, streetName, additionalAddress, zipCode, city};
        ApiV0.updateMeAddress(options)
            .then(saveSuccessData => {
                const evt = `save address`;
                analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, displayName);
                this.doEndEdit(() => this.props.onUpdated())
            })
            .catch(apiError => {
                const errorMessage = CUtils.userErrorOf("save address", t, apiError);
                const evt = `save address error`;
                analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, displayName);
                var newState = this.getDefaultAddress();
                newState.errorMessage = errorMessage;
                newState.wip = false;
                this.setState(newState, () => this.warnAutoHide(50000));
            });
    }

    warnHide() {
        this.setState({showWarn: false});
    }

    warnAutoHide(timeoutMs) {
        var app = this;
        this.setState({
            showWarn: true
        }, () => {
            setTimeout(() => {
                app.warnHide();
            }, timeoutMs)
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        var name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleKeyDown(event) {
        if (event.keyCode === KEY_ESCAPE && this.state.editAddress === true) {
            this.doCancelAddress();
            return;
        }
    }

    render() {
        if (this.state.wip === true) {
            return (<CLoadInProgress/>)
        }
        const {t} = this.props;
        const allowToRemoveAddress = false;
        const editAddress = this.state.editAddress === true;
        const showAddress = this.state.editAddress !== true;
        const alert = this.state.errorMessage ?
            (<div className="editAddressError"><Alert variant="warning" show={this.state.showWarn}>
                {this.state.errorMessage}
            </Alert></div>)
            : (null);
        const {
            firstname, lastname, streetNumber, streetName, additionalAddress,
            zipCode, city
        } = this.state;
        const submitDisabled = !(firstname !== ""
            && lastname !== ""
            && streetName !== ""
            && zipCode !== ""
            && city !== ""
        );
        const emptyForm = showAddress && firstname === "";
        const requiredStyle = editAddress ? "required" : "";

        const formAddress = (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="form-group">
                    <Row>
                        <Form.Group as={Col} sm={4} controlId="formGridFirstname">
                            <Form.Label className={requiredStyle}>{t('address.form.firstname.label')}</Form.Label>
                            <Form.Control
                                name="firstname"
                                type="text"
                                value={firstname}
                                autoComplete="new-password"
                                className="form-control"
                                placeholder={t('address.form.firstname.placeholder')}
                                pattern={CConstants.FIRSTNAME_REGEX}
                                maxLength={CConstants.FIRSTNAME_MAX_LENGTH}
                                onChange={this.handleInputChange.bind(this)}
                                disabled={showAddress}
                                ref={(input) => {
                                    this.firstnameInput = input;
                                }}/>
                            <CFormTip hide={showAddress} value={firstname} minShow={80}
                                      max={CConstants.FIRSTNAME_MAX_LENGTH}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridLastname">
                            <Form.Label className={requiredStyle}>{t('address.form.name.label')}</Form.Label>
                            <Form.Control
                                name="lastname"
                                type="text"
                                value={lastname}
                                autoComplete="new-password"
                                className="form-control"
                                placeholder={t('address.form.name.placeholder')}
                                pattern={CConstants.LASTNAME_REGEX}
                                maxLength={CConstants.LASTNAME_MAX_LENGTH}
                                onChange={this.handleInputChange.bind(this)}
                                disabled={showAddress}
                                ref={(input) => {
                                    this.lastnameInput = input;
                                }}/>
                            <CFormTip hide={showAddress} value={lastname} minShow={80}
                                      max={CConstants.LASTNAME_MAX_LENGTH}/>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} sm={4} controlId="formGridStreetNumber">
                            <Form.Label>{t('address.form.streetNumber.label')}</Form.Label>
                            <Form.Control
                                name="streetNumber"
                                type="text"
                                value={streetNumber}
                                autoComplete="new-password"
                                className="form-control"
                                placeholder={t('address.form.streetNumber.placeholder')}
                                pattern={CConstants.STREET_NUMBER_REGEX}
                                maxLength={CConstants.STREET_NUMBER_MAX_LENGTH}
                                onChange={this.handleInputChange.bind(this)}
                                disabled={showAddress}
                                ref={(input) => {
                                    this.streetNumberInput = input;
                                }}/>
                            <CFormTip hide={showAddress} value={streetNumber} minShow={35}
                                      max={CConstants.STREET_NUMBER_MAX_LENGTH}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridStreetName">
                            <Form.Label className={requiredStyle}>{t('address.form.streetName.label')}</Form.Label>
                            <Form.Control
                                name="streetName"
                                type="text"
                                value={streetName}
                                autoComplete="new-password"
                                className="form-control"
                                placeholder={t('address.form.streetName.placeholder')}
                                pattern={CConstants.STREET_NAME_REGEX}
                                maxLength={CConstants.STREET_NAME_MAX_LENGTH}
                                onChange={this.handleInputChange.bind(this)}
                                disabled={showAddress}
                                ref={(input) => {
                                    this.streetNameInput = input;
                                }}/>
                            <CFormTip hide={showAddress} value={streetName} minShow={200}
                                      max={CConstants.STREET_NAME_MAX_LENGTH}/>
                        </Form.Group>
                    </Row>
                    {editAddress || additionalAddress !== "" ? (
                        <Row>
                            <Form.Group as={Col} sm={10} controlId="formGridAdditional">
                                <Form.Label>{t('address.form.addressExtra.label')}</Form.Label>
                                <Form.Control
                                    name="additionalAddress"
                                    type="text"
                                    value={additionalAddress}
                                    autoComplete="new-password"
                                    className="form-control"
                                    placeholder={t('address.form.addressExtra.placeholder')}
                                    pattern={CConstants.ADDITIONAL_REGEX}
                                    maxLength={CConstants.ADDITIONAL_MAX_LENGTH}
                                    onChange={this.handleInputChange.bind(this)}
                                    disabled={showAddress}
                                    ref={(input) => {
                                        this.additionalAddressInput = input;
                                    }}/>
                                <CFormTip hide={showAddress} value={additionalAddress} minShow={200}
                                          max={CConstants.ADDITIONAL_MAX_LENGTH}/>
                            </Form.Group>
                        </Row>
                    ) : null}

                    <Row>
                        <Form.Group as={Col} sm={4} controlId="formGridZipCode">
                            <Form.Label className={requiredStyle}>{t('address.form.zipCode.label')}</Form.Label>
                            <Form.Control
                                name="zipCode"
                                type="text"
                                value={zipCode}
                                autoComplete="new-password"
                                className="form-control"
                                placeholder={t('address.form.zipCode.placeholder')}
                                pattern={CConstants.ZIP_CODE_REGEX}
                                maxLength={CConstants.ZIP_CODE_MAX_LENGTH}
                                onChange={this.handleInputChange.bind(this)}
                                disabled={showAddress}
                                ref={(input) => {
                                    this.zipCodeInput = input;
                                }}/>
                            <CFormTip hide={showAddress} value={zipCode} minShow={35}
                                      max={CConstants.ZIP_CODE_MAX_LENGTH}/>
                        </Form.Group>

                        <Form.Group as={Col} sm={4} controlId="formGridCity">
                            <Form.Label className={requiredStyle}>{t('address.form.city.label')}</Form.Label>
                            <Form.Control
                                name="city"
                                type="text"
                                value={city}
                                autoComplete="new-password"
                                className="form-control"
                                placeholder={t('address.form.city.placeholder')}
                                pattern={CConstants.CITY_REGEX}
                                maxLength={CConstants.CITY_MAX_LENGTH}
                                onChange={this.handleInputChange.bind(this)}
                                disabled={showAddress}
                                ref={(input) => {
                                    this.cityInput = input;
                                }}/>
                            <CFormTip hide={showAddress} value={city} minShow={200} max={CConstants.CITY_MAX_LENGTH}/>
                        </Form.Group>
                        {this.props.country ? (
                            <Form.Group as={Col} sm={4} controlId="formGridCountry">
                                <Form.Label>{t('address.form.country.label')}</Form.Label>
                                <Form.Control
                                    name="country"
                                    type="text"
                                    value={t(`countries.${this.props.country}`)}
                                    className="form-control"
                                    disabled/>
                            </Form.Group>
                        ) : null}
                    </Row>
                    {editAddress ? (<span className="requiredLegend"> : {t('address.form.mandatory')}</span>) : null}
                    {editAddress ? alert : null}
                    {editAddress ? (
                        <div className="btnPanel">
                            <button onClick={this.doCancelAddress.bind(this)}
                                    className="btn btn-secondary btn-padding"
                                    title={t('address.form.doCancelTitle')}
                                    type="button">{t('address.form.doCancel')}</button>

                            <button className="btn btn-secondary btn-padding"
                                    title={t('address.form.doSaveTitle')}
                                    type="submit"
                                    disabled={submitDisabled}>{t('address.form.doSave')}</button>
                        </div>
                    ) : null}
                </div>
            </form>
        );

        const addressValue = submitDisabled ?
            (<Card onClick={this.doEditAddress.bind(this)}>
                <Card.Body style={{
                    backgroundColor: 'white',
                    color: 'grey',
                    cursor: 'pointer'
                }}>{t('address.toComplete')}</Card.Body>
            </Card>)
            : (<Card><Card.Body style={{backgroundColor: 'white'}}>
                {firstname} {lastname}<br/>
                {streetNumber} {streetName}<br/>
                {additionalAddress}<br/>
                {zipCode} {city}
            </Card.Body></Card>);

        return (
            <div className="form-group" onKeyDown={this.handleKeyDown.bind(this)}>
                {editAddress !== true ?
                    (<div style={{float: 'right'}}
                          onClick={this.doEditAddress.bind(this)}>
                        <button className="btn-delete" title={t('address.form.doEditTitle')}><TiEdit/>
                            {emptyForm ?
                                (<span>{t('address.form.doAdd')}</span>) :
                                (<span>{t('address.form.doEdit')}</span>)}
                        </button>
                    </div>) : null}
                {allowToRemoveAddress && editAddress !== true && !emptyForm ?
                    (<div style={{float: 'right'}}
                          onClick={this.doRemoveAddress.bind(this)}>
                        <button className="btn-delete" title={t('address.form.doDeleteTitle')}>
                            <TiDelete/> {t('address.form.doDelete')}
                        </button>
                    </div>) : null}
                {editAddress ? (<label><FaAddressCard/> {t('address.form.setupShippingAddress')}</label>) :
                    (<label><FaAddressCard/> {t('address.form.myAddress')}</label>)}
                {editAddress ? formAddress : addressValue}
            </div>
        );
    }

}

export default withTranslation('common')(CEditAddress);