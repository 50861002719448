import React, {Component} from 'react';
import {RiDeleteBin5Line} from 'react-icons/ri';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {Button} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';

class CDeleteListingButton extends Component {

    render() {
        const {t, isAdmin, listingId, listingTitle, size, onDeleteListing} = this.props;
        if (isAdmin !== true) { //  || !window.isChListing(listingId)
            return null;
        }
        const buttonSize = window.isSet(size) ? size : "sm";
        const deleteTooltip = t('editListing.delete') + " " + listingTitle + " id:" + listingId;
        return (
            <Button variant="light" size={buttonSize} style={{margin: 5, padding: 2}}
                    onClick={() => onDeleteListing(listingId, listingTitle)}>
         <span data-tooltip-content={deleteTooltip} id="ttDeleteListing">
           Supprimer <RiDeleteBin5Line/>
         </span>
                <ReactTooltip anchorId="ttDeleteListing"/>
            </Button>
        );
    }
}

export default withTranslation('common')(CDeleteListingButton);
