const DAY_IN_MS = 24 * 60 * 60 * 1000;
const cookiesDefaultExpirationDays = 60;

// REFERENCE is UserDao - PASS_RULE_CODE
// at least one uppercase letter, one digit, one lowercase letter, length between 8,255
const passwordRegex = '^(?=.*[A-Z])(?=.*\\d)(?=.*[a-z]).{8,255}$';

export const CConstants = {
    // also to add to webpack.config.js
    STRIPE_API_KEY: 'REACT_APP_CH_STRIPE_PUBLIC_API_KEY',
    PAYPAL_CLIENT_ID: 'REACT_APP_CH_PAYPAL_CLIENT_ID',
    PAYPAL_BASE_URL: 'REACT_APP_CH_PAYPAL_BASE_URL',
    // end of front-end variables
    GG_CATEGORY: {ADMIN: "ADMIN", MENU: "MENU", LISTING: "LISTING", AUTH: "AUTH", LEGAL: "LEGAL", OTHER: "OTHER"},

    EMAIL_REGEX: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
    EMAIL_MAX_LENGTH: 254,

    LOGIN_REGEX: '^[a-zA-Z-_0-9]{5,100}$',
    LOGIN_MAX_LENGTH: 100,

    PASSWORD_REGEX: passwordRegex,
    PASSWORD_MAX_LENGTH: 255,

    FIRSTNAME_REGEX: '^.{1,100}$',
    FIRSTNAME_MAX_LENGTH: 100,
    LASTNAME_REGEX: '^.{1,100}$',
    LASTNAME_MAX_LENGTH: 100,
    STREET_NUMBER_REGEX_REGEX: '^.{1,50}$',
    STREET_NUMBER_MAX_LENGTH: 50,
    STREET_NAME_REGEX: '^.{1,255}$',
    STREET_NAME_MAX_LENGTH: 255,
    ADDITIONAL_REGEX: '^.{0,255}$',
    ADDITIONAL_MAX_LENGTH: 255,
    ZIP_CODE_REGEX: '^.{1,50}$',
    ZIP_CODE_MAX_LENGTH: 50,
    CITY_REGEX: '^.{0,255}$',
    CITY_MAX_LENGTH: 255,

    //~ add code
    CODE_NAME_MAX_LENGTH: 100,
    CODE_NAME_REGEX: '^[\\w %€]{5,100}$',
    CODE_PREFIX_MAX_LENGTH: 20,
    CODE_PREFIX_REGEX: '^[a-zA-Z-_0-9]{1,20}$',
    CODE_DISCOUNT_REGEX: '^[0-9,\\.]{1,5}$',
    //~ use code
    CODE_MAX_LENGTH: 50,
    CODE_REGEX: '^[a-zA-Z-_0-9]{1,50}$',

    CH_COPYRIGHTS: '© 2024 Creharmony.fr',

    COOKIE_EXPIRATION_DEFAULT_DAYS: cookiesDefaultExpirationDays,
    COOKIE_DEFAULT_SET_OPTIONS: {path: '/', maxAge: cookiesDefaultExpirationDays * DAY_IN_MS},//cookiesOptions
    COOKIE_ONE_DAY_SET_OPTIONS: {path: '/', maxAge: DAY_IN_MS},//cookiesOptions
    LANG_ACCEPTED: ['fr', 'en'],
    COOKIE_LANG_KEY: 'site-lang',
};