import React, {Component} from 'react'
import './CListingGallery.css';

// ImageGallery
// code: https://github.com/xiaolin/react-image-gallery/
// samples:
// https://reactjsexample.com/react-carousel-image-gallery-component-with-thumbnail-and-mobile-support/
// https://www.linxtion.com/demo/react-image-gallery/
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {decode} from 'html-entities';
import EventEmitter from '../services/EventEmitter';

class CListingGallery extends Component {
    constructor(props) {
        super(props);
        const images = this.props.listing.images.map((image, index) => {
            const original = this.generateShowedUrl(index);
            const thumbnail = this.generateSelectorUrl(index);
            const imageId = this.getImageId(index);
            const originalAlt = props.title;
            const thumbnailAlt = props.title;
            let result = {original, thumbnail, imageId, index, originalAlt, thumbnailAlt};
            if (original.endsWith(".mp4")) {
                const renderItem = (item) => {
                    return (<div>
                        <video controls muted autoPlay loop className="chGalleryVideo">
                            <source src={item.original} type="video/mp4"/>
                        </video>
                    </div>)
                };
                result = {...result, renderItem};
            }
            return result;
        });
        const startIndex = 0;
        this.state = {images, startIndex};
    }

    _(toDecode) {
        return decode(toDecode);
    }

    generateSelectorUrl(index) {
        const smallImage = this.props.listing.images[index]["url_75x75"];
        return smallImage ? smallImage : this.props.listing.images[index]["url_fullxfull"];
    }

    generateShowedUrl(index) {
        return this.props.listing.images[index]["url_fullxfull"];
        // return this.props.listing.images[index]["url_570xN"];
    }

    getImageId(index) {
        return this.props.listing.images[index]["listing_image_id"];
    }

    getStartIndex() {
        if (!this.props.selectedImageId) {
            return this.state.startIndex;
        }
        let availableImage = this.state.images.filter(i => i.imageId === parseInt(this.props.selectedImageId, 10));
        // DEBUG // console.info("availableImage", availableImage);
        return (availableImage && availableImage.length > 0) ? availableImage[0].index : this.state.startIndex;
    }

    onGalleryUserSlide(index) {
        const imageId = this.getImageId(index);
        if (!window.isSet(imageId)) {
            // DEBUG // console.log(`user slide to index:${index} but unable to find related imageId`);
            return;
        }
        // DEBUG // console.log(`user slide to index:${index} imageId:${imageId}`);
        EventEmitter.emitSlideImage(imageId)
    }

    render() {
        const {images} = this.state;
        const hasMultiImages = this.state.images && this.state.images.length > 1;
        const SHOWED = true;
        const NOT_SHOWED = false;
        const galleryHeight = 300;
        // cf. https://github.com/xiaolin/react-image-gallery
        // DEBUG // console.log(images)
        return hasMultiImages ?
            (<div className="chGallery"><ImageGallery items={images}
                                                      thumbnailPosition="right"
                                                      slideOnThumbnailOver="true"
                                                      showBullets={NOT_SHOWED}
                                                      showPlayButton={NOT_SHOWED}
                                                      showFullscreenButton={SHOWED}
                                                      startIndex={this.getStartIndex()}
                                                      originalHeight={galleryHeight}
                                                      lazyLoad={true}
                                                      onSlide={this.onGalleryUserSlide.bind(this)}
            /></div>) :
            (<div className="chGalleryAlone"><ImageGallery items={images}
                                                           showThumbnails={NOT_SHOWED}
                                                           showBullets={NOT_SHOWED}
                                                           showPlayButton={NOT_SHOWED}
                                                           showFullscreenButton={SHOWED}
                                                           originalHeight={galleryHeight}
                                                           lazyLoad={true}
                                                           className="chGallery"
                                                           onSlide={this.onGalleryUserSlide.bind(this)}
            /></div>);
    }
}

export default CListingGallery;