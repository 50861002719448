import React, {Component} from 'react';
import ApiV0 from '../services/ApiV0';
import CUtils from '../services/CUtils';
import {Alert} from 'react-bootstrap';
import CLoadInProgress from '../common/CLoadInProgress';
import './CListing.css';
import {analyticsPageView} from '../core/AnalyticsManager';
import {withTranslation} from 'react-i18next';

class CListingId extends Component {
    state = {
        errorMessage: null,
        infoMessage: null
    }

    componentDidMount() {
        this.getListing();
    }

    getListing() {
        const {t} = this.props;
        ApiV0.getListing(this.props.listingId)
            .then(listingResult => {
                this.setState({listing: listingResult, errorMessage: null, infoMessage: null});
            })
            .catch(getError => {
                const userError = CUtils.userErrorOf("getListing", t, getError);
                this.setState({errorMessage: userError});
            });
        analyticsPageView('lid ' + this.props.listingId);
    }

    decodedTitle() {
        return CUtils._(this.state.listing.title);
    }

    getSectionName() {
        const lang = ApiV0.getLang();
        return CUtils.getSectionTitle(this.state.section, lang);
    }

    render() {
        const lang = ApiV0.getLang();
        const breadcrumb = null;
        const listing = this.state.listing;
        const errorPlace = this.state.errorMessage ? (
            <Alert variant="warning">{this.state.errorMessage}</Alert>) : null;
        const productUrl = CUtils.getListingLink(listing, lang);

        if (!listing) {
            return errorPlace ? (<>{breadcrumb}{errorPlace}</>) : (<CLoadInProgress/>);
        }
        document.location.href = productUrl;
        return (<>Redirect</>);
    }
}

export default withTranslation('common')(CListingId);
