import React, {Component} from 'react'
import './CLeftMenu.css';
import {Nav} from 'react-bootstrap';
import CUtils from '../services/CUtils'
import ApiV0 from '../services/ApiV0'
import {withTranslation} from 'react-i18next';

class CLeftMenu extends Component {
    render() {
        if (!this.props.sections) {
            return null;
        }
        const {t} = this.props;
        const lang = ApiV0.getLang();
        const activeKey = window.location.pathname; // "/"
        const sections = this.props.sections;
        return (
            <Nav defaultActiveKey={activeKey} className="flex-column">
                { /*
                <Nav.Link href="/" className="noLink">
                 <div className="chmiddleLogo">
                   <img width="180px" src="/images/ch_left_sorciere.jpg" alt={t('ch.logo')} className="chLogo"/>
                 </div>
                </Nav.Link>
             */}
                <Nav.Link href="/" className="cMenuEntry">{t('ch.home')}</Nav.Link>
                {sections.map((s, key) => {
                    const sectionRef = CUtils.getSectionLink(s, lang);
                    const title = CUtils.getSectionTitle(s, lang);
                    return (<Nav.Link href={sectionRef} eventKey={sectionRef} className="cMenuEntry" key={key}>
                        {title}
                    </Nav.Link>)
                })}
            </Nav>
        )
    }
}

export default withTranslation('common')(CLeftMenu);