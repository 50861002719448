import React, {Component} from 'react'
import {Card} from 'react-bootstrap';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import ApiV0 from '../services/ApiV0';
import EventEmitter from '../services/EventEmitter';
import CUtils from '../services/CUtils';
import CPrice from '../common/CPrice';
import {CConstants} from '../common/CConstants';
import './CListingChoice.css';
import {analyticsEvent} from '../core/AnalyticsManager';
import {withTranslation} from 'react-i18next';

class CListingChoice extends Component {
    state = {
        loaded: false,
        productLink: this.generateProductLink(),
        lang: ApiV0.getLang()
    };

    handleLangChange(event) {
        this.setState({lang: event.detail.newLang});
    }

    componentDidMount() {
        document.body.addEventListener(EventEmitter.CH_SET_LANG, this.handleLangChange.bind(this));
    }

    componentWillUnmount() {
        document.body.removeEventListener(EventEmitter.CH_SET_LANG, this.handleLangChange.bind(this));
    }


    generateImageUrl() {
        const image1 = this.props.product.images[0]["url_570xN"];
        const image2 = this.props.product.images[0]["url_fullxfull"];
        return image1 ? image1 : image2;
    }

    generateProductLink() {
        return CUtils.getListingLink(this.props.product, ApiV0.getLang())
    }

    onClick() {
        const origin = this.props.origin ? this.props.origin : null;
        if (origin !== null) {
            const lang = this.state.lang;
            const listing = this.props.product;
            const listingId = listing.listing_id;
            const title = CUtils.getListingShortTitle(listing, 45, lang);
            const evt = `${origin} ${listingId} - ${title}`;
            analyticsEvent(CConstants.GG_CATEGORY.LISTING, evt, evt, 0);
        }
        document.location = this.state.productLink;
    }

    render() {
        const {t} = this.props;
        const lang = ApiV0.getLang();
        const listing = this.props.product;
        const listingId = listing.listing_id;
        const listingNameDivId = `listing-name-${listingId}`;
        const currency = listing.currency_code === 'EUR' ? "€" : listing.currency_code;
        const productClass = "cproductParent"; // this.state.loaded ? "cproductParent" : "cproductLoading";
        const cardTitle = CUtils.getListingShortTitle(listing, 45, lang);
        const tooltipTitle = CUtils.getListingShortTitle(listing, 200, lang);
        const decodedDescription = CUtils.getListingDescription(listing, lang);
        const tags = CUtils.getListingTagsComaSeparated(listing, lang);
        const isAvailable = listing.quantity > 0;
        return listing ? (
                <a href={this.state.productLink} className={productClass}>
                    <Card className="cproduct"
                          onClick={this.onClick.bind(this)}
                          id={`card-${listingId}`}
                          itemProp="itemListElement"
                          itemScope
                          itemType="https://schema.org/ListItem"
                          name={tooltipTitle}
                          ref={this.wrapper}
                    >
                        <Card.Img variant="top"
                                  src={this.generateImageUrl()} onLoad={() => this.setState({loaded: true})}
                                  className="cproductImage"
                                  alt={cardTitle}
                        />
                        <div className="cproductTitle">
                            {isAvailable ? (<>
                                <div className="cproductPriceText"><CPrice price={listing.price} currency={currency}/></div>
                                <div className="cproductTitleText" data-tooltip-content={tooltipTitle} id={listingNameDivId}
                                     itemProp="name"
                                >{cardTitle}</div>
                            </>) : (<>
                                <div className="cproductOutText">{t('listing.choice.unavailable')}</div>
                                <div className="cproductOutTitleText" data-tooltip-content={tooltipTitle}
                                     id={listingNameDivId}
                                     itemProp="name"
                                >{cardTitle}</div>
                            </>)}
                            <meta itemProp="position" content={this.props.index}/>
                            <meta itemProp="url" content={this.generateProductLink()}/>
                            <div className="hidden">
                                <b>{tooltipTitle}</b>
                                <p>{decodedDescription}</p>
                                <p>{tags}</p>
                            </div>
                        </div>
                        <ReactTooltip anchorId={listingNameDivId} data-place="bottom" className="listTooltip"/>
                    </Card>
                </a>
            ) :
            (<div>.</div>);
    }
}

export default withTranslation('common')(CListingChoice);