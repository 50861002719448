import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import Switch from "react-switch"; // https://www.npmjs.com/package/react-switch
import './CCookiesModal.css';
import CUtils from '../services/CUtils';
import {CConstants} from '../common/CConstants';
import {analyticsEvent} from '../core/AnalyticsManager';
import {withTranslation} from 'react-i18next';

class CCookiesModal extends Component {
    state = {
        consentCookies: CUtils.hasConsentCookie(false),
        consentStatsCookies: CUtils.hasConsentStatsCookie(false),
        modalShow: true,
        formConsentCookies: CUtils.hasConsentCookie(true),
        formConsentStatsCookies: CUtils.hasConsentStatsCookie(true),
    }

    onTerminate() {
        CUtils.setConsentCookie(this.state.formConsentCookies);
        CUtils.setConsentStatsCookies(this.state.formConsentStatsCookies);
        const evt = `cookies validate - stats=${this.state.formConsentStatsCookies}`;
        analyticsEvent(CConstants.GG_CATEGORY.LEGAL, evt, evt);
        this.setState({modalShow: false});
    }

    componentDidMount() {
    }

    handleConsentChange(formConsentCookies) {
        // DEBUG // console.log("handleConsentChange", formConsentCookies)
        this.setState({formConsentCookies});
    }

    handleConsentStatsChange(formConsentStatsCookies) {
        // DEBUG // console.log("handleConsentStatsChange", formConsentStatsCookies)
        this.setState({formConsentStatsCookies});
    }

    render() {
        const {t} = this.props;
        const modalShow = this.state.modalShow;
        // const formConsentCookies = this.state.formConsentCookies;
        // const basicState = formConsentCookies ? "accepter" : "refuser";
        // const basicCurrentState = this.state.consentCookies ? "actif" : "inactif";
        const formConsentStatsCookies = this.state.formConsentStatsCookies;
        const statsState = formConsentStatsCookies ? t('cookies.accept') : t('cookies.refuse');
        const statsCurrentState = this.state.consentStatsCookies ? t('cookies.active') : t('cookies.inactive');

        const modalContent = (<>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">{t('common.cookiesTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('cookies.browser')}
                &#160;(<a target="_new" className="txt"
                          href="https://www.cnil.fr/fr/definition/cookie">{t('cookies.moreDetails')}</a>).
                <br/><br/>
                <h4>{t('cookies.mandatoryInfo')} {/*<sup><small>{basicCurrentState}</small></sup>*/}</h4>
                <div>
                    {t('cookies.mandatoryInfoP')}<br/>
                    &#160;
                    {/*
               <div className="alignRight">
                 <div className="switchState">{basicState}</div>
                 <Switch onChange={(val) => this.handleConsentChange(val)} checked={formConsentCookies} disabled={true}/>
               </div>
                */}
                </div>
                <h4>{t('cookies.analyticsInfo')} <sup><small>{statsCurrentState}</small></sup></h4>
                <div>
                    {t('cookies.analyticsInfoP')}
                    <div className="alignRight">
                        <div className="switchState">{statsState}</div>
                        <Switch onChange={(val) => this.handleConsentStatsChange(val)}
                                checked={formConsentStatsCookies}/>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {this.state.formConsentCookies ? (<>{t('cookies.retention')}
                    &#160;{CConstants.COOKIE_EXPIRATION_DEFAULT_DAYS} {t('cookies.expiration_day',
                        {'count': CConstants.COOKIE_EXPIRATION_DEFAULT_DAYS})}.</>) : null}
                <Button variant="info" onClick={this.onTerminate.bind(this)}>{t('cookies.submit')}</Button>
            </Modal.Footer>
        </>);

        return (<>
            <Modal
                show={modalShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                className="smallScreen"
            >
                {modalContent}
            </Modal>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                className="largeScreen"
            >
                {modalContent}
            </Modal>
        </>);
    }
}

export default withTranslation('common')(CCookiesModal);