import {RxChatBubble} from 'react-icons/rx';
import {withTranslation} from 'react-i18next';
import {analyticsEvent} from '../core/AnalyticsManager';
import './CYourOpinion.css';
import {CConstants} from "./CConstants";

const CYourOpinion = (props) => {
    const {t} = props
    const opinionLink = t('home.opinionLink');
    const opinionTitle = t('home.opinionTitle');
    if (opinionLink === undefined || opinionTitle === null) {
        return null;
    }
    const evt = "CYourOpinion";
    const onClickIt = () => {
        analyticsEvent(CConstants.GG_CATEGORY.OTHER, evt, evt);
        window.open(opinionLink, "_blank");
    }
    return (<div className="yourOpinion"
                 title={opinionTitle}
                 onClick={onClickIt}
    ><RxChatBubble/> {t('home.opinion')}</div>);
}
export default withTranslation('common')(CYourOpinion);

