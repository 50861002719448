import React from "react";
import {Tooltip as ReactTooltip} from 'react-tooltip';

import dayjs from 'dayjs';

// no translation for now (admin only)
const CTimeAgo = (props) => {
    if (props.hide === true) {
        return null;
    }
    const ttKey = "ttTimeAgo" + getRandomInt(30);
    const timeAgo = timeAgoFr(props.timestamp);
    const timeAgoTooltip = dayjs.unix(props.timestamp / 1000).format('DD/MM/YYYY HH:mm');
    return (<><span data-tooltip-content={timeAgoTooltip} id={ttKey}>{timeAgo}</span><ReactTooltip
        anchorId={ttKey}/></>);
}

export default CTimeAgo;

const timeAgoFr = (prevDate) => {
    const diff = Number(new Date()) - prevDate;
    const oppositeDiff = prevDate - Number(new Date());
    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;
    switch (true) {
        case diff > 0 && diff < minute:
            const seconds = Math.round(diff / 1000);
            return `il y a ${seconds} ${seconds > 1 ? 'secondes' : 'seconde'}`
        case diff > 0 && diff < hour:
            return 'il y a ' + Math.round(diff / minute) + ' minutes';
        case diff > 0 && diff < day:
            return 'il y a ' + Math.round(diff / hour) + ' heures';
        case diff > 0 && diff < month:
            return 'il y a ' + Math.round(diff / day) + ' jours';
        case diff > 0 && diff < year:
            return 'il y a ' + Math.round(diff / month) + ' mois';
        case diff > 0 && diff > year:
            return 'il y a ' + Math.round(diff / year) + ' ans';
        case oppositeDiff < minute:
            const oppositeSeconds = Math.round(oppositeDiff / 1000);
            return `dans ${oppositeSeconds} ${oppositeSeconds > 1 ? 'secondes' : 'seconde'}`
        case oppositeDiff < hour:
            return Math.round(oppositeDiff / minute) + ' minutes';
        case oppositeDiff < day:
            return Math.round(oppositeDiff / hour) + ' heures';
        case oppositeDiff < month:
            return Math.round(oppositeDiff / day) + ' jours';
        case oppositeDiff < year:
            return Math.round(oppositeDiff / month) + ' mois';
        case oppositeDiff > year:
            return Math.round(oppositeDiff / year) + ' ans';
        default:
            return 'e';
    }
};

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}