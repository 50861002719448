import React, {Component} from 'react';
import {GrFormEdit} from 'react-icons/gr';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {Button} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';

class CEditListingButton extends Component {

    editListing() {
        const sectionId = this.props.sectionId;
        const listingId = this.props.listingId;
        if (sectionId === undefined || listingId === undefined) {
            console.warn("CEditListingButton missing arg", {sectionId, listingId});
            return;
        }
        if (window.isChListing(listingId)) {
            document.location = `/admin/listings?listingId=${listingId}`;
        } else {
            document.location = `/admin/listing/${sectionId}/${listingId}`;
        }
    }

    render() {
        const {t, isAdmin, size} = this.props;
        if (isAdmin !== true) {
            return null;
        }
        const buttonSize = window.isSet(size) ? size : "sm";
        return (
            <Button variant="light" size={buttonSize} style={{margin: 5, padding: 2}}
                    onClick={this.editListing.bind(this)}>
          <span data-tooltip-content={t('editListing.edit')} id="ttEditListing">
            Modifier <GrFormEdit/>
          </span>
                <ReactTooltip anchorId="ttEditListing"/>
            </Button>
        );
    }
}

export default withTranslation('common')(CEditListingButton);