import React, {Component} from 'react';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {BiEnvelope, BiPackage} from 'react-icons/bi';

// no translation for now (admin only)
class CShippingType extends Component {

    render() {
        if (this.props.type === 'colis') {
            return (<span data-tooltip-content="Envoi par colis postal" id="ttShippingA"><BiPackage/><ReactTooltip
                anchorId="ttShippingA"/></span>);
        }
        if (this.props.type === 'enveloppe') {
            return (<span data-tooltip-content="Envoi sous enveloppe" id="ttShippingB"><BiEnvelope/><ReactTooltip
                anchorId="ttShippingB"/></span>);
        }
        return (<span>{this.props.type}</span>);
    }
}

export default CShippingType;