import React, {Component} from 'react';
import {Alert, Form} from 'react-bootstrap';
import {TiEdit} from 'react-icons/ti';
import {GiFlyingFlag} from 'react-icons/gi';
import ApiV0 from '../services/ApiV0';
import {CConstants} from '../common/CConstants';
import CUtils from '../services/CUtils';
import {analyticsEvent} from '../core/AnalyticsManager';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';

class CEditCountry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countries: null,
            errorMessage: null
        }
    }

    componentDidMount() {
        const country = _.get(this.props, 'meProfile.country', null);
        if (country === null) {
            this.doLoadCountries();
        }
    }

    doLoadCountries() {
        ApiV0.countries().then((countries) => {
            countries.sort();
            this.setState({countries})
        });
    }

    selectCountry(event) {
        const {t} = this.props;
        const country = event.target.value;
        const displayName = this.props.meProfile.displayName;
        this.setState({country, updateInProgress: true}, () => {
            ApiV0.updateMe(country).then((countries) => {
                const evt = `profile update country ${country}`;
                analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, displayName);
                this.setState({updateInProgress: false, errorMessage: null},
                    () => this.props.updated(country === "other" ? "" : t('country.countryUpdated')));
            }).catch(apiError => {
                const errorMessage = CUtils.userErrorOf("update country", t, apiError);
                const evt = `profile update country ${country} error`;
                analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, displayName);
                this.setState({updateInProgress: false, errorMessage});
            });
        })
    }


    render() {
        const {t} = this.props;
        const errorPlace = this.state.errorMessage ? (
            <Alert variant="warning">{this.state.errorMessage}</Alert>) : null;
        const countries = this.state.countries ? this.state.countries.sort() : this.state.countries;
        var country = _.get(this.props, 'meProfile.country', '');
        if (country === null) {
            country = '';
        }
        return (<>
            {errorPlace}
            {countries ? (
                    <Form.Group controlId="profileSelectCountry">
                        <Form.Label><GiFlyingFlag/> {t('country.chooseACountry')}</Form.Label>
                        <Form.Select value={country} onChange={this.selectCountry.bind(this)}>
                            <option key="other" value="other">-- {t('country.choose')} --</option>
                            {this.state.countries.map((val, index) => {
                                return (
                                    <option key={index} index={index} value={val}>{t(`countries.${val}`)}</option>)
                            })}
                        </Form.Select>
                        <Form.Text className="text-muted">{t('country.countryUseful')}
                            {t('country.countryMissing')} <a href="/contact">{t('country.countryAskUs')}</a></Form.Text>
                    </Form.Group>
                ) :
                (<div className="form-group" onClick={this.doLoadCountries.bind(this)}>
                    <label><GiFlyingFlag/> {t('country.shippingCountry')}</label>
                    <div style={{float: 'right'}}>
                        <button className="btn-delete" title={t('country.doEditTitle')}><TiEdit/> {t('country.doEdit')}
                        </button>
                    </div>
                    <input name="countryName" type="text"
                           className="form-control disabled profileValue"
                           value={t(`countries.${country}`)} disabled/>
                </div>)
            }
        </>);
    }

}

export default withTranslation('common')(CEditCountry);