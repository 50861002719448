import React from "react";
import {FaCaretLeft} from 'react-icons/fa';
import {GiSeaDragon} from 'react-icons/gi';
import {Breadcrumb} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';

import CYourOpinion from '../common/CYourOpinion';
import './CBreadcrumb.css';

const CBreadcrumb = (props) => {
    const {
        t,
        cbId,
        leftCaret, // show left caret before breadcrumb
        withHome,  // show home links
        homeLabel, homeLink, // option override home label/link
        secondLabel, secondLink, secondClick, // option set second level label/link
        thirdLabel, thirdLink, // option set third level label/link
        activeLabel, activeLink, activeClass, activeDragon // option set active level label and option: active link
    } = props;
    // breadcrumb identifier
    const breadcrumbId = cbId ? cbId : "cBreadcrumb";

    // top / home level
    const hLabel = homeLabel ? homeLabel : t('breadcrumb.home');
    const hLink = homeLink ? homeLink : "/";

    // second level
    const sLabel = secondLabel ? secondLabel : null;
    const sLink = secondLink ? secondLink : null;
    const sClick = secondClick ? secondClick : null;

    // third level
    const tLabel = thirdLabel ? thirdLabel : null;
    const tLink = thirdLink ? thirdLink : null;

    // second level
    const aLabel = activeLabel ? activeLabel : null;
    const aLink = activeLink ? activeLink : null;
    const aClass = activeClass ? activeClass : "";

    return (<>
        {withHome === false ? (<div className="breadcrumbRight"><CYourOpinion/></div>) : null}
        <Breadcrumb id={breadcrumbId} itemScope itemType="https://schema.org/BreadcrumbList">
            {withHome !== false ? (
                <Breadcrumb.Item href={hLink} itemScope itemProp="itemListElement"
                                 itemType="https://schema.org/ListItem">
                    {leftCaret === true ? (<><FaCaretLeft/>&#160;</>) : null}
                    <span itemScope itemType="https://schema.org/WebPage"
                          itemProp="item" itemID={hLink}><span itemProp="name">{hLabel}</span></span>
                    <meta itemProp="position" content="1"/>
                </Breadcrumb.Item>
            ) : null}
            {sLabel !== null && sLink !== null ? (
                <Breadcrumb.Item href={sLink} itemScope itemProp="itemListElement"
                                 itemType="https://schema.org/ListItem">
                    {sClick !== null ?
                        (<span itemScope itemType="https://schema.org/WebPage"
                               itemProp="item" itemID={sLink} onClick={sClick}><span
                            itemProp="name">{sLabel}</span></span>) :
                        (<span itemScope itemType="https://schema.org/WebPage"
                               itemProp="item" itemID={sLink}><span itemProp="name">{sLabel}</span></span>)}
                    <meta itemProp="position" content="2"/>
                </Breadcrumb.Item>
            ) : null}
            {tLabel !== null && tLink !== null ? (
                <Breadcrumb.Item href={tLink} itemScope itemProp="itemListElement"
                                 itemType="https://schema.org/ListItem">
           <span itemScope itemType="https://schema.org/WebPage"
                 itemProp="item" itemID={tLink}><span itemProp="name">{tLabel}</span></span>
                    <meta itemProp="position" content="3"/>
                </Breadcrumb.Item>
            ) : null}
            {aLabel !== null ? (
                <Breadcrumb.Item href={aLink} className={aClass} active itemScope itemProp="itemListElement"
                                 itemType="https://schema.org/ListItem">
                    {activeDragon === true ? (<><GiSeaDragon/>&#160;</>) : null}
                    {aLink !== null ? (
                            <span itemScope itemType="https://schema.org/WebPage"
                                  itemProp="item" itemID={aLink}><span itemProp="name">{aLabel}</span></span>
                        ) :
                        (<span itemProp="name">{aLabel}</span>)}
                    <meta itemProp="position" content="4"/>
                </Breadcrumb.Item>
            ) : null}
        </Breadcrumb>
    </>);
}

export default withTranslation('common')(CBreadcrumb);