import React, {Component} from 'react';
import {GrDuplicate} from 'react-icons/gr';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {Button} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';

export default withTranslation('common')(class CDuplicateListingButton extends Component {

    duplicateListing() {
        const sectionId = this.props.sectionId;
        const listingId = this.props.listingId;
        if (sectionId === undefined || listingId === undefined) {
            console.warn("CDuplicateListingButton missing arg", {sectionId, listingId});
            return;
        }
        document.location = `/admin/listings?listingId=${listingId}&action=duplicate`;
    }

    render() {
        const {t, isAdmin, size} = this.props;
        if (isAdmin !== true) {
            return null;
        }
        const buttonSize = window.isSet(size) ? size : "sm";
        return (
            <Button variant="light" size={buttonSize} style={{margin: 5, padding: 2}}
                    onClick={this.duplicateListing.bind(this)}>
          <span data-tooltip-content={t('editListing.duplicate')} id="ttDuplicateListing">
            <GrDuplicate/>
          </span>
                <ReactTooltip anchorId="ttDuplicateListing"/>
            </Button>
        );
    }
})