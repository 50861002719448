import i18next from "i18next";
import detector from "i18next-browser-languagedetector";

import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";

import {CConstants} from './common/CConstants';
import Cookies from 'universal-cookie';
import EventEmitter from "./services/EventEmitter";
import ApiV0 from "./services/ApiV0";

const DEBUG_LANG_DETECTOR = false;
const DEFAULT_LOCALE = 'fr';
const ACCEPTED_LOCALES = ['fr', 'en'];

const getCookieValueOrElse = (cookieName, elseValue) => {
    const cookies = new Cookies();
    return cookies.get(cookieName) !== undefined ? cookies.get(cookieName) : elseValue;
}

/**
 * detect locale
 * server side cf. ExpressServer && common.getLocale
 */
const retrieveCurrentLocale = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // precedence about locale
    // 1) from user order (query param lang)
    const queryLang = queryParams.get('lang');
    if (ACCEPTED_LOCALES.includes(queryLang)) {
        DEBUG_LANG_DETECTOR && console.log("queryLang", queryLang);
        return queryLang;
    }
    // 2) from user historical preference (cookie)
    const cookieLang = getCookieValueOrElse(CConstants.COOKIE_LANG_KEY, null);
    if (ACCEPTED_LOCALES.includes(cookieLang)) {
        DEBUG_LANG_DETECTOR && console.log("cookieLang", cookieLang);
        return cookieLang;
    }
    // 3) from server detection
    const docLang = document.documentElement.lang;
    if (ACCEPTED_LOCALES.includes(docLang)) {
        DEBUG_LANG_DETECTOR && console.log("docLang", docLang);
        return docLang;
    }
    return DEFAULT_LOCALE;
}

ApiV0.lang = retrieveCurrentLocale();
EventEmitter.emitSetLang(ApiV0.lang);

i18next
    .use(detector)
    .init({
        interpolation: {escapeValue: false},  // React already does escaping
        "lng": ApiV0.lang, // language to use
        resources: {
            fr: {common: common_fr},
            en: {common: common_en},
        },
        fallbackLng: DEFAULT_LOCALE, // use fr if detected lng is not available
    });

export default i18next;