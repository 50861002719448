import React from "react";
import {Spinner} from 'react-bootstrap';
import './CLoadInProgress.css';

import {withTranslation} from 'react-i18next';

const CLoadInProgress = (props) => {
    const {t} = props;
    const msg = props.msg;
    return (
        <>
            <div className="loadSpinner">
                <Spinner animation="border" role="status"/><br/>
            </div>
            <div className="loadText">
                <center>{t('ch.loading')}</center>
                {msg ? (<center>{msg}</center>) : null}
            </div>
        </>);
}

export default withTranslation('common')(CLoadInProgress);