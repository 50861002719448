import CUtils from '../services/CUtils';

const analyticsDebug = false;

/**
 * Umami track function : https://umami.is/docs/tracker-functions
 */
export const isUmamiActivated = function () {
    return isAnalyticsAuthorized() && window.umami;
}

export const isAnalyticsAuthorized = function () {
    return CUtils.hasConsentStatsCookie() === true;
}

export const analyticsPageView = function (title) {
    const url = document.location.origin + document.location.pathname + document.location.search;
    if (isUmamiActivated()) {
        if (analyticsDebug) {
            console.info("umami Analytics:pageview", url);
        }
        window.umami.track(props => ({
            ...props,
            hostname: window.location.hostname,
            language: navigator.language,
            referrer: document.referrer,
            screen: `${window.screen.width}x${window.screen.height}`,
            title: window.isSet(title) ? title : document.title,
            url,
        }));
        // TODO  website
    }
}

export const analyticsEvent = function (categoryValue, actionValue, label, value) {
    if (isUmamiActivated()) {
        if (analyticsDebug) {
            console.info("umami Analytics:event", actionValue);
        }
        const action = CUtils.maxString(actionValue, 50);// umami limit event name to 50
        const category = CUtils.maxString(categoryValue, 50);// umami limit event name to 50
        window.umami.track(action, {category, label, value});
    }
}

//https://analystadmin.medium.com/implementing-google-analytics-and-google-tag-manager-into-a-react-js-app-e986579cd0ee